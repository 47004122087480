var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "section",
      { staticClass: "flex flex-col items-center justify-center" },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/comingsoon.png"),
            alt: "Coming Soon",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }